<template>
	<div id="retrieve"></div>
</template>

<script>
import { userAction } from "@/api/user";

export default {
	metaInfo: {
		title: "通訊資料驗證"
	},
	async mounted() {
		try {
			await userAction.verifyCode({ code: this.$route.query.token });
			this.$root.$message.open({
				status: 200,
				message: "驗證成功！"
			});
		} catch (err) {
			this.$root.$message.open({
				status: 400,
				message: "驗證連結已過期"
			});
		}
		this.$router.push({ name: "home" });
	}
};
</script>
